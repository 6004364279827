import { useState, useEffect } from 'react';
import firebase from '../../util/firebase';
import { httpsCallable } from 'firebase/functions';
import * as XLSX from 'xlsx';
import $ from 'jquery';


import { File } from '../../util/Input';

import styles from '../../css/util/admin/Dispatch.module.css';


var Dispatch = ({ toggle }) => {

    useEffect(() => {

        let element = document.querySelector('div#dispatch');
        setTimeout(() => {
            $(element).addClass(styles['active']);
            $(element.querySelector('div[name=container]')).addClass(styles['active']);
        }, 0);


        $('body').addClass('scrollBlock');


        return () => {
            $('body').removeClass('scrollBlock');
        }
        
    }, []);

    var close = (e) => {

        if(!$(e.target).is('#dispatch') && e !== true) return 0;

        let element = document.querySelector('div#dispatch');
        $(element).removeClass(styles['active']);
        $(element.querySelector('div[name=container]')).removeClass(styles['active']);
        setTimeout(() => {
            toggle(false);
        }, 520);

    }

    // dispatch loading
    let [loading, setLoading] = useState(false);
    let [fileError, setFileError] = useState('');


    var upload = async (e) => {
        e.preventDefault();

        let file = document.querySelector('input[name=dispatchFile]').files[0];

        if(!file){
            setFileError('파일을 업로드해주세요.');
            return 0;
        }

        if(fileError) return 0;

        setLoading(true);
        
        let workbook = await XLSX.read(await xlsxReader(file), { type : 'binary' })

        let datas = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
        .map((data) => {
            return {
                name: data.구매자명,
                trackingNumber: data.송장번호,
                tel: data.수취인연락처,
                ordererTel: data.구매자연락처
            }
        })

        return httpsCallable(firebase.functions, 'dispatchOrder')(datas)
        .then(() => {

            setLoading(false);
            close(true);
            return true;

        })
        .catch((err) => {
            setLoading(false);
            console.error(err);
            return false;
        })

    }

    var xlsxReader = file => {
        return new Promise((resolve, reject) => {
            var fr = new FileReader();  
            fr.onload = () => resolve(fr.result);
            fr.readAsBinaryString(file);
        });
    }

    return (
        <div id="dispatch" className={styles['div-root']} onClick={close}>
            <div name="container" className={styles['container']}>

                <button className={styles['close']} onClick={() => close(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <path style={{fill: "#1d1d1d"}} d="M20.71,20l5.3-5.3c.2-.2,.2-.51,0-.71s-.51-.2-.71,0l-5.3,5.3-5.3-5.3c-.2-.2-.51-.2-.71,0s-.2,.51,0,.71l5.3,5.3-5.3,5.3c-.2,.2-.2,.51,0,.71,.2,.2,.51,.2,.71,0l5.3-5.3,5.3,5.3c.2,.2,.51,.2,.71,0,.2-.2,.2-.51,0-.71l-5.3-5.3Z"></path>
                    </svg>
                </button>

                <section className={styles['message']}>
                    <h4 className={styles['header']}>
                        발송 처리하기
                    </h4>
                    <p className={styles['copy']}>
                        네이버 스마트스토어 자동 발송처리. <br/>
                        PNC한테 받은 송장 파일 업로드 후 <br/>
                        확인 버튼 누르기. <br/>
                    </p>
                </section>
                <section className={styles['action']}>
                    <form onSubmit={upload}>
                        {
                            <File
                            name="dispatchFile"
                            accept=".xls,.xlsx"
                            placeholder="송장파일"
                            btn="업로드하기"
                            error={fileError}
                            setError={setFileError}/>
                        }
                        <button className={
                            !loading ? styles['submit'] : [
                                styles['submit'],
                                styles['loading']
                            ].join(' ')}>확인</button>
                    </form>
                </section>
            </div>
        </div>
    )

}

export default Dispatch
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import styles from '../css/util/Input.module.css';
import { test , message } from '../util/format';

var Input = ({ type , name , placeholder , value , disabled , format , error , setError }) => {
    const inputRef = useRef();

    var onChange = (e) => {

        if(!test[format](e.target.value)){
            setError(message[format])
        }else{
            setError('')
        }

    }

    if(error) inputRef.current.focus();

    return (
        <>
            <div className={error == '' ?
                styles['container'] : [
                    styles['container'],
                    styles['error']
                ].join(' ')}>

                <input ref={inputRef} placeholder=" " autoComplete="off" autoCapitalize="off" autoCorrect="off" spellCheck="false"
                onChange={format ? onChange : null}
                className={styles['input-default']}
                type={type}
                name={name}
                value={value ? value : undefined}
                disabled={disabled ? true : false}
                ></input>

                <span className={styles['input-default-span']}>
                    {placeholder}
                </span>
            </div>
            <p className={error == '' ?
                styles['text-error'] : [
                    styles['text-error'],
                    styles['active']
                ].join(' ')}
            >{error}</p>
        </>
    )

}

var Checkbox = ({ name , checked , error , setError , children }) => {

    return (

        <>
            <div className={styles['checkbox']}>
                <label>
                    {children}
                    <input type="checkbox" name={name}></input>
                    <span className={styles['checkbox-default-span']}></span>
                </label>
                <p className={error == '' ?
                    styles['text-error'] : [
                        styles['text-error'],
                        styles['active']
                    ].join(' ')}
                >{error}</p>
            </div>
        </>

    )

}

var File = ({ name , accept , placeholder , btn , error , setError }) => {

    let fileNameRef = useRef();

    var fileNameUpdate = (e) => {

        let fileName = e.target.value.split(/(\\|\/)/g).pop();

        fileNameRef.current.value = fileName;

    }

    return (

        <>
            <div className={error == '' ? [
                    styles['container'],
                    styles['file']
                ].join(' ') : [
                    styles['container'],
                    styles['file'],
                    styles['error']
                ].join(' ')}>

                <input ref={fileNameRef} className={styles['input-default']} type="text" placeholder=" " autoComplete="off" autoCapitalize="off" autoCorrect="off" spellCheck="false" disabled></input>
                <span className={styles['input-default-span']}>{placeholder}</span>

                <label
                htmlFor={name}
                className={styles['input-file-default-label']}
                >{btn}</label>

                <input
                id={name}
                name={name}
                accept={accept}
                className={styles['input-file-default']}
                onChange={fileNameUpdate}
                type="file"></input>
            </div>
            <p className={error == '' ?
                styles['text-error'] : [
                    styles['text-error'],
                    styles['active']
                ].join(' ')}
            >{error}</p>
        </>

    )

}

const errorMsg = {
    'auth/email-not-found': '당신 누구야.',
    'auth/wrong-password': '때론, 비밀번호를 잊어야만 새로 만들 수 있다.'
}

export {
    Input,
    Checkbox,
    File,
    errorMsg
}
import { useState } from 'react';

import { Nav } from '../util/Nav';
import Subscribe from '../util/Subscribe';
import styles from '../css/Home.module.css';


var Home = () => {

    let [subscribeShow, setSubscribeShow] = useState(false);

    var toggleSubscribe = (value) => {
        setSubscribeShow(value);
    }

    return (
    
    <>
        {!subscribeShow ? null :
        <Subscribe toggle={toggleSubscribe} />}

        <Nav subscribe={toggleSubscribe} />

        <div className={styles['div-front']}>
            <div className={styles['div-front-container']}>
                
            </div>
        </div>
    </>);
}

export default Home;
import { useState , useEffect } from 'react';
import firebase from '../../util/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { NavAdmin } from '../../util/Nav';

import Dispatch from '../../util/admin/Dispatch';

var News = () => {

    useEffect(() => {
        onAuthStateChanged(firebase.auth, (user) => {
            if(!user){
                window.location.replace('/');
            }
        })
    }, []);

    let [dispatchShow, setDispatchShow] = useState(false);

    var toggleDispatch = (value) => {
        setDispatchShow(value);
    }

    return (
        <>

            {!dispatchShow ? null :
            <Dispatch toggle={toggleDispatch} />}

            <NavAdmin dispatch={toggleDispatch} />
        </>
    )

}

export default News;
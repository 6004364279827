
import styles from '../css/util/Nav.module.css';
import { Link } from 'react-router-dom';

var Nav = ({ subscribe }) => {

    return (

        <nav>
            <div className={styles['container']}>

                <section>
                    <Link className={styles['btn-logo']} to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.66 20">
                            <path style={{fill: '#ffffff'}} d="M7.77,2.45c0,3.15,1.32,4.43,3.67,5.05v3.3c-2.12-.12-4.53-1.11-5.68-2.45-1.11,1.38-3.56,2.49-5.75,2.7v-3.32c2.35-.64,3.69-2.06,3.69-5.25V.1h4.08v2.35ZM1.4,15.65c0-1.17.51-2.1,1.38-2.78,1.36-1.09,3.69-1.59,6.45-1.59s5.11.49,6.47,1.59c.87.68,1.36,1.61,1.36,2.78s-.49,2.08-1.36,2.76c-1.36,1.09-3.69,1.59-6.47,1.59s-5.09-.49-6.45-1.59c-.87-.68-1.38-1.61-1.38-2.76ZM13.04,15.65c0-.27-.1-.47-.35-.64-.51-.39-1.67-.56-3.46-.56s-2.92.16-3.46.56c-.23.16-.35.37-.35.64,0,.25.12.45.35.64.54.37,1.69.54,3.46.54s2.95-.16,3.46-.54c.25-.19.35-.39.35-.64ZM9.35,5.79v-3.28h3.21V0h4.08v11.02h-4.08v-5.23h-3.21Z"/>
                            <path style={{fill: '#ffffff'}} d="M27.17,7.99h5.27v-3.5h4.02v3.5h8.4v3.17h-17.69v-3.17ZM28.01,15.88c0-1.03.41-1.87,1.19-2.51,1.34-1.07,3.71-1.61,6.82-1.61s5.5.54,6.82,1.61c.78.64,1.22,1.48,1.22,2.51s-.43,1.87-1.22,2.51c-1.32,1.09-3.71,1.61-6.82,1.61s-5.48-.51-6.82-1.61c-.78-.64-1.19-1.46-1.19-2.51ZM39.69,3.4h-11.49V.23h15.53v3.58c0,1.15-.14,2.57-.39,3.52h-3.93c.25-.91.29-2.08.29-3.15v-.78ZM40.06,15.88c0-.16-.06-.35-.23-.47-.45-.43-1.65-.62-3.81-.62s-3.36.19-3.81.62c-.16.12-.23.31-.23.47,0,.19.06.35.23.49.45.41,1.65.62,3.81.62s3.36-.21,3.81-.62c.16-.14.23-.31.23-.49Z"/>
                            <path style={{fill: '#ffffff'}} d="M59.07,7.62h5.93v3.25h-5.93v4.18h4.72c1.59,0,2.47-.23,3.13-.84V0h4.08v19.59h-4.08v-2.18c-.68.68-1.67.95-3.46.95h-8.42V.33h10.18v3.3h-6.14v4Z"/>
                            <path style={{fill: '#ffffff'}} d="M83.44,7.48h-1.44v-3.05h1.48c1.11,0,2.18,0,3.01-.25.02-.29.04-.6.04-.93h-4.53V.23h7.95v2.37c0,.47-.02.93-.06,1.36h1.09V.1h3.3v11.06h-3.3v-3.95h-1.81c-1.3,3.13-4.06,4.45-7.6,4.9v-3.09c1.67-.23,2.95-.72,3.79-1.71-.64.1-1.32.16-1.92.16ZM90.46,17.05c-1.61,1.57-4.51,2.68-8.18,2.78v-3.3c4.08-.41,6.16-1.67,6.16-4.24v-.39h4.08v.39c0,2.53,2.14,3.83,6.14,4.24v3.3c-3.91-.06-6.63-1.22-8.2-2.78ZM94.99,0h3.46v12.38h-3.46V0Z"/>
                        </svg>
                    </Link>
                </section>

                <section>
                    <Link className={styles['btn-router']} to="/news">뉴스레터 보기</Link>
                    <Link className={styles['btn-router']} to="http://successticket.kr/products/9674516281" target="_blank">주마등 보러가기</Link>
                    <button className={styles['btn-action']} onClick={() => subscribe(true)}>구독하기</button>
                </section>

            </div>
        </nav>

    )

}

var NavAdmin = ({ dispatch }) => {

    return (

        <nav>
            <div className={styles['container']}>

                <section>
                    <Link className={styles['btn-logo']} to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.66 20">
                            <path style={{fill: '#ffffff'}} d="M7.77,2.45c0,3.15,1.32,4.43,3.67,5.05v3.3c-2.12-.12-4.53-1.11-5.68-2.45-1.11,1.38-3.56,2.49-5.75,2.7v-3.32c2.35-.64,3.69-2.06,3.69-5.25V.1h4.08v2.35ZM1.4,15.65c0-1.17.51-2.1,1.38-2.78,1.36-1.09,3.69-1.59,6.45-1.59s5.11.49,6.47,1.59c.87.68,1.36,1.61,1.36,2.78s-.49,2.08-1.36,2.76c-1.36,1.09-3.69,1.59-6.47,1.59s-5.09-.49-6.45-1.59c-.87-.68-1.38-1.61-1.38-2.76ZM13.04,15.65c0-.27-.1-.47-.35-.64-.51-.39-1.67-.56-3.46-.56s-2.92.16-3.46.56c-.23.16-.35.37-.35.64,0,.25.12.45.35.64.54.37,1.69.54,3.46.54s2.95-.16,3.46-.54c.25-.19.35-.39.35-.64ZM9.35,5.79v-3.28h3.21V0h4.08v11.02h-4.08v-5.23h-3.21Z"/>
                            <path style={{fill: '#ffffff'}} d="M27.17,7.99h5.27v-3.5h4.02v3.5h8.4v3.17h-17.69v-3.17ZM28.01,15.88c0-1.03.41-1.87,1.19-2.51,1.34-1.07,3.71-1.61,6.82-1.61s5.5.54,6.82,1.61c.78.64,1.22,1.48,1.22,2.51s-.43,1.87-1.22,2.51c-1.32,1.09-3.71,1.61-6.82,1.61s-5.48-.51-6.82-1.61c-.78-.64-1.19-1.46-1.19-2.51ZM39.69,3.4h-11.49V.23h15.53v3.58c0,1.15-.14,2.57-.39,3.52h-3.93c.25-.91.29-2.08.29-3.15v-.78ZM40.06,15.88c0-.16-.06-.35-.23-.47-.45-.43-1.65-.62-3.81-.62s-3.36.19-3.81.62c-.16.12-.23.31-.23.47,0,.19.06.35.23.49.45.41,1.65.62,3.81.62s3.36-.21,3.81-.62c.16-.14.23-.31.23-.49Z"/>
                            <path style={{fill: '#ffffff'}} d="M59.07,7.62h5.93v3.25h-5.93v4.18h4.72c1.59,0,2.47-.23,3.13-.84V0h4.08v19.59h-4.08v-2.18c-.68.68-1.67.95-3.46.95h-8.42V.33h10.18v3.3h-6.14v4Z"/>
                            <path style={{fill: '#ffffff'}} d="M83.44,7.48h-1.44v-3.05h1.48c1.11,0,2.18,0,3.01-.25.02-.29.04-.6.04-.93h-4.53V.23h7.95v2.37c0,.47-.02.93-.06,1.36h1.09V.1h3.3v11.06h-3.3v-3.95h-1.81c-1.3,3.13-4.06,4.45-7.6,4.9v-3.09c1.67-.23,2.95-.72,3.79-1.71-.64.1-1.32.16-1.92.16ZM90.46,17.05c-1.61,1.57-4.51,2.68-8.18,2.78v-3.3c4.08-.41,6.16-1.67,6.16-4.24v-.39h4.08v.39c0,2.53,2.14,3.83,6.14,4.24v3.3c-3.91-.06-6.63-1.22-8.2-2.78ZM94.99,0h3.46v12.38h-3.46V0Z"/>
                        </svg>
                    </Link>
                </section>

                <section>
                    <a className={styles['btn-router']} onClick={() => dispatch(true)}>발송처리</a>
                    <Link className={styles['btn-router']} to="/admin/news">뉴스레터</Link>
                    <Link className={styles['btn-router']} to="/admin/subscribers">구독자</Link>
                    <Link className={styles['btn-action']} to="/admin/news/create">뉴스레터 작성하기</Link>
                </section>

            </div>
        </nav>

    )

}


export {
    Nav,
    NavAdmin
}
export let test = {

    email: (value) =>{

        if(!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value)){
            return false;
        }
    
        let local = value.split('@')[0];
        let dotIndexOf = local.indexOf('.');
    
        if(dotIndexOf == local.length - 1 || dotIndexOf == 0){
            return false;
        }
    
        return true;

    },

    phone: (value) => {

        if(!/01[016789][^0][0-9]{2,3}[0-9]{3,4}/.test(value)){
    
            return false;
    
        }
    
        return true;

    },

    nickname: (value) => {

        if(value.length < 4){
            
            return false;

        }

        return true;

    },

    password: (value) => {

        if(value.length < 6){
            
            return false;

        }

        return true;

    }

}

export let message = {
    'nickname': '닉네임은 4글자 이상입니다.',
    'email': '이메일을 정확히 입력해주세요.',
    'password': '6자리 이상으로 입력해주세요'
}
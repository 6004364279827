import { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import styles from '../css/util/Subscribe.module.css';

import { Input , Checkbox } from '../util/Input';

var Subscribe = ({ toggle }) => {

    useEffect(() => {

        let element = document.querySelector('div#subscribe');
        setTimeout(() => {
            $(element).addClass(styles['active']);
            $(element.querySelector('div[name=container]')).addClass(styles['active']);
        }, 0);


        $('body').addClass('scrollBlock');


        return () => {
            $('body').removeClass('scrollBlock');
        }
        
    }, []);


    var close = (e) => {

        if(!$(e.target).is('#subscribe')) return 0;

        let element = document.querySelector('div#subscribe');
        $(element).removeClass(styles['active']);
        $(element.querySelector('div[name=container]')).removeClass(styles['active']);
        setTimeout(() => {
            toggle(false);
        }, 520);

    }

    // subscribe loading
    let [loading, setLoading] = useState(false);

    // errors
    let [nicknameError, setNicknameError] = useState('');
    let [emailError, setEmailError] = useState('');
    let [agreeError, setAgreeError] = useState('');
    let [promoError, setPromoError] = useState('');
    
    return (
        <div id="subscribe" className={styles['div-root']} onClick={close}>
            <div name="container" className={styles['container']}>
                <section className={styles['message']}>
                    <h4 className={styles['header']}>
                        “구원은 개인의 몫이다”
                        <br/><br/>
                        <span>- 성공티켓 -</span>
                    </h4>
                    <p className={styles['copy']}>
                        이 뉴스레터의 내용을 통해, 당신의 삶, 주변인 그리고 당신에게 긍정적인 영향을 줄 수 있는 내면의 힘을 찾길 바랍니다.
                    </p>
                </section>
                <section className={styles['action']}>
                    {
                        <Input
                        type="text"
                        name="nickname"
                        placeholder="닉네임"
                        format="nickname"
                        error={nicknameError}
                        setError={setNicknameError}/>
                    }
                    {
                        <Input
                        type="email"
                        name="email"
                        placeholder="이메일"
                        format="email"
                        error={emailError}
                        setError={setEmailError}/>
                    }
                    {
                        <Checkbox
                        name={'agree'}
                        checked={true}
                        error={agreeError}
                        setError={setAgreeError}>
                            [필수] 성공티켓 인사이트 <Link to={'/policy'}>이용약관</Link> <Link to={'/privacy'}>개인정보처리방침</Link> 에 동의합니다.
                        </Checkbox>
                    }
                    {
                        <Checkbox
                        name={'promo'}
                        checked={true}
                        error={promoError}
                        setError={setPromoError}>
                            [선택] 성공티켓 인사이트에서 보내는 광고성 정보 수신에 동의합니다.
                        </Checkbox>
                    }
                    <button className={
                        !loading ? styles['submit'] : [
                            styles['submit'],
                            styles['loading']
                        ].join(' ')}>구독하기</button>
                </section>
            </div>
        </div>
    )

}


export default Subscribe;
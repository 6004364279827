import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from "firebase/auth";
import { getStorage , connectStorageEmulator } from 'firebase/storage';
import { getFunctions , connectFunctionsEmulator  } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyDCHsBCioCkKULxl5ZdruIJR5tYP30cHUE",
    authDomain: "successticket-f5c49.firebaseapp.com",
    projectId: "successticket-f5c49",
    storageBucket: "successticket-f5c49.appspot.com",
    messagingSenderId: "119860504813",
    appId: "1:119860504813:web:b94f3d6b392f88c31044e4",
    measurementId: "G-W624BK6JNG"
};
  
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();
const functions = getFunctions(app);
const storage = getStorage(app);

// connectFunctionsEmulator(functions, 'localhost', 5001);
// connectStorageEmulator(storage, 'localhost', 9199)


export default {
    auth,
    functions,
    storage
}
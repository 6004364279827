import { useState , useEffect } from 'react';
import firebase from '../../util/firebase';
import { onAuthStateChanged , setPersistence , signInWithCustomToken , browserLocalPersistence } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

import { Input , errorMsg } from '../../util/Input';

import styles from '../../css/admin/Index.module.css';

var Index = () => {

    useEffect(() => {
        onAuthStateChanged(firebase.auth, (user) => {
            if(user){
                window.location.replace('/admin/news');
            }
        })
    }, []);

    let [loading, setLoading] = useState(false);
    let [emailError, setEmailError] = useState('');
    let [passwordError, setPasswordError] = useState('');

    var login = async (e) => {
        
        e.preventDefault();

        let email = document.querySelector('input[name=email]').value;
        let password = document.querySelector('input[name=password]').value;

        if(!email || !password){
            
            if(!email) setEmailError('이메일을 입력해주세요.');
            if(!password) setPasswordError('비밀번호를 입력해주세요.');
            return 0;

        }

        if(emailError || passwordError) return 0;

        setLoading(true);

        return httpsCallable(firebase.functions, 'adminSignin')({
            email: email,
            password: password
        })
        .then((result) => {
    
            setPersistence(firebase.auth, browserLocalPersistence)
            .then(() => {
                
                signInWithCustomToken(firebase.auth, result.data.token)
                .catch((err) => {
                    console.error(err);
                });
    
            })
            .catch((err) => {
                console.error(err);
            });

        })
        .catch((err) => {
            console.error(err.message);
            setLoading(false);
            switch(err.message){

                case 'auth/email-not-found':
                    setEmailError(errorMsg[err.message]);
                    break;
                case 'auth/wrong-password':
                    setPasswordError(errorMsg[err.message]);
                    break;

            }

            return false;

        })

    }
    
    return (
        <>
            <div className={styles['div-front']}>
                <form className={styles['div-front-container']} onSubmit={login}>
                    <p className={styles['title']}>관리자 로그인</p>
                    {
                        <Input
                        type="email"
                        name="email"
                        placeholder="이메일"
                        format="email"
                        error={emailError}
                        setError={setEmailError}/>
                    }
                    {
                        <Input
                        type="password"
                        name="password"
                        placeholder="비밀번호"
                        format="password"
                        error={passwordError}
                        setError={setPasswordError}/>
                    }
                    <button type="submit" formNoValidate
                    className={
                        !loading ? styles['submit'] : [
                            styles['submit'],
                            styles['loading']
                        ].join(' ')
                    }>로그인</button>
                </form>
            </div>
            <div className={styles['div-footer']}>
                <div className={styles['div-footer-container']}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.78 48">
                        <path style={{fill: '#bfbfbf'}} d="M18.64,5.88c0,7.56,3.16,10.63,8.8,12.11v7.91c-5.09-.3-10.88-2.67-13.64-5.88-2.67,3.31-8.55,5.98-13.79,6.48v-7.96c5.64-1.53,8.85-4.94,8.85-12.61V.25h9.79v5.64ZM3.36,37.57c0-2.82,1.24-5.04,3.31-6.67,3.26-2.62,8.85-3.81,15.47-3.81s12.26,1.19,15.52,3.81c2.08,1.63,3.26,3.86,3.26,6.67s-1.19,4.99-3.26,6.62c-3.26,2.62-8.85,3.81-15.52,3.81s-12.21-1.19-15.47-3.81c-2.08-1.63-3.31-3.86-3.31-6.62ZM31.29,37.57c0-.64-.25-1.14-.84-1.53-1.24-.94-4-1.33-8.3-1.33s-7.02.4-8.3,1.33c-.54.4-.84.89-.84,1.53,0,.59.3,1.09.84,1.53,1.29.89,4.05,1.29,8.3,1.29s7.07-.4,8.3-1.29c.59-.44.84-.94.84-1.53ZM22.44,13.89v-7.86h7.71V0h9.79v26.45h-9.79v-12.56h-7.71Z"/>
                        <path style={{fill: '#bfbfbf'}} d="M65.2,19.18h12.66v-8.4h9.64v8.4h20.17v7.61h-42.46v-7.61ZM67.23,38.11c0-2.47.99-4.5,2.87-6.03,3.21-2.57,8.9-3.86,16.36-3.86s13.2,1.29,16.36,3.86c1.88,1.53,2.92,3.56,2.92,6.03s-1.04,4.5-2.92,6.03c-3.16,2.62-8.9,3.86-16.36,3.86s-13.15-1.24-16.36-3.86c-1.88-1.53-2.87-3.51-2.87-6.03ZM95.26,8.16h-27.58V.54h37.27v8.6c0,2.77-.35,6.18-.94,8.45h-9.44c.59-2.18.69-4.99.69-7.56v-1.88ZM96.15,38.11c0-.4-.15-.84-.54-1.14-1.09-1.04-3.95-1.48-9.15-1.48s-8.06.44-9.15,1.48c-.4.3-.54.74-.54,1.14,0,.44.15.84.54,1.19,1.09.99,3.95,1.48,9.15,1.48s8.06-.49,9.15-1.48c.4-.35.54-.74.54-1.19Z"/>
                        <path style={{fill: '#bfbfbf'}} d="M141.77,18.29h14.24v7.81h-14.24v10.04h11.32c3.81,0,5.93-.54,7.51-2.03V0h9.79v47.01h-9.79v-5.24c-1.63,1.63-4,2.27-8.3,2.27h-20.22V.79h24.42v7.91h-14.73v9.59Z"/>
                        <path style={{fill: '#bfbfbf'}} d="M200.25,17.94h-3.46v-7.32h3.56c2.67,0,5.24,0,7.22-.59.05-.69.1-1.43.1-2.22h-10.88V.54h19.08v5.68c0,1.14-.05,2.22-.15,3.26h2.62V.25h7.91v26.55h-7.91v-9.49h-4.35c-3.11,7.51-9.74,10.68-18.24,11.77v-7.42c4-.54,7.07-1.73,9.1-4.1-1.53.25-3.16.4-4.6.4ZM217.11,40.93c-3.86,3.76-10.83,6.43-19.63,6.67v-7.91c9.79-.99,14.78-4,14.78-10.18v-.94h9.79v.94c0,6.08,5.14,9.19,14.73,10.18v7.91c-9.39-.15-15.92-2.92-19.67-6.67ZM227.98,0h8.3v29.71h-8.3V0Z"/>
                    </svg>
                    <p>Copyright © 주식회사 더아라한. 모든 권리 보유.</p>
                </div>
            </div>
        </>
    );

}

export default Index;
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';

import './css/index.css';
import Home from './router/Home'
import Admin from './router/admin/Index'
import News from './router/admin/News'
import firebase from './util/firebase';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <Routes>
            <Route path="/" element={<Home/>}></Route>


            <Route path='/admin' element={<Admin/>}></Route>
            <Route path='/admin/news' element={<News/>}></Route>
        </Routes>
    </Router>
);